import { Box, Container, Paper } from "@mui/material";
import useGetLogo from "src/components/logo/hooks/use-logo";
import useIsDarkMode from "src/hooks/use-is-darkmode";
import "./style.css";

const AuthLayout = ({ children, login }) => {
  const logo = useGetLogo();
  const isDark = useIsDarkMode();

  return (
    <Box
      className="loginBgMobile"
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: isDark ? "#161c24" : "#f5f5f5",
      }}
    >
      <Box
        fixed
        maxWidth="md"
        sx={{
          height: "100%",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: login ? "600px" : "400px",
          margin: "auto",
        }}
      >
        <Paper sx={{ padding: { md: 3 }, width: "100%" }}>
          <Box sx={{ display: "flex", mb: 3, mt: 5, justifyContent: "center" }}>
            <img style={{ width: "200px", height: "auto" }} src={logo} />
          </Box>
          {children}
        </Paper>
      </Box>
    </Box>
  );
};

export default AuthLayout;
